import React, {useCallback, useEffect, useState} from "react";
import QuestionComponent from "./question/question.component";
import {useBank} from "../../../../../contexts/banks-context";
import {useEthosNotification} from "../../../../../contexts/ethos-notification-context";
import {EnumQuestionOptionResponseType, Question, Section, ServiceCustomer} from "../../../../../models";
import _ from "lodash";
import {Button, Col, Nav, Row, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronCircleDown,
    faChevronCircleUp,
    faExternalLinkAlt,
    faPlus,
    faSave,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import AddServiceCustomerForm
    from "../../../../../ui-components/forms-components/customer/steps/services-section/add-service-customer-form/add-service-customer-form";
import {useServiceCustomer} from "../../../../../contexts/service-customer-context";
import {useEthosModal} from "../../../../../contexts/ethos-modal-context";
import {useParams} from "react-router-dom";
import "./survey-step-element.css";
import {v4 as uuidv4} from "uuid";

interface AutomatismValue {
    bonis: number;
    malis: number;
    monthBonis: number;
}

export interface SurveyStepElementProps {
    customerId?: string;
    survey: Section[];
    availableServices: any[];
    readOnly: boolean;
    updateSurvey: (survey: Section[]) => void;
    setUnsavedChanges: (value: boolean) => void;
    overrideClass?: {
        row: { class: string };
        input: { class: string };
        survey?: { class: string };
    };
}

interface TabSections {
    name: string;
    key: string;
    order: number;
    sections: Section[];
}

const SurveyStepElement = ({
                               availableServices = [],
                               customerId,
                               survey,
                               readOnly = false,
                               updateSurvey = () => {
                               },
                               setUnsavedChanges = () => {
                               },
                               overrideClass,
                           }: SurveyStepElementProps) => {
    const {id} = useParams<{ id: string }>();
    const [mySurvey, setMySurvey] = useState<Section[]>(survey);
    const [tabsSections, setTabsSections] = useState<TabSections[]>([]);
    const [otherSections, setOtherSections] = useState<Section[]>([]);
    const [accourdionStatus, setAccordionStatus] = useState<{
        [key: string]: boolean;
    }>({});
    const [answersWithError] = useState([]);
    const {getBanks} = useBank();
    const {handleError, showSuccessToast} = useEthosNotification();
    const [banks, setBanks] = useState([]);
    const {newServiceCustomer, deleteServiceCustomer} = useServiceCustomer();
    const {openEthosConfirmModal} = useEthosModal();

    const doUpdateSurvey = () => {
        if (answersWithError && answersWithError.length) {
            return;
        }
        updateSurvey(mySurvey);
        setUnsavedChanges(false);
    };

    useEffect(() => {
        setMySurvey([...survey]);
    }, [survey]);

    useEffect(() => {
        var tabs = mySurvey.filter((x) => x.layout && x.layout.type === "tabs");
        var otherSections = mySurvey.filter((x) => !x.layout || x.layout.type !== "tabs");
        if (tabs) {
            var grouped = _.groupBy(tabs, (x) => x.layout.name);
            var sorted = _.sortBy(Object.values(grouped), (x) => x[0].order);
            var sortedTabs = sorted.map(
                (x) =>
                    ({
                        name: (x[0] as Section).layout.name,
                        key: (x[0] as Section).layout.name.replace(" ", "_").toLowerCase(),
                        order: (x[0] as Section).order,
                        sections: x,
                    } as TabSections)
            );
        }
        setTabsSections(sortedTabs);
        setOtherSections(otherSections);
    }, [mySurvey]);

    const setResponse = (section: Section, question: Question, outerIndex: number, innerIndex: number, value: any) => {
        const needToParse = [EnumQuestionOptionResponseType.Number, EnumQuestionOptionResponseType.Money]
        if (needToParse.includes(question.responseType)) {
            question.response = parseFloat(value)
        } else {
            question.response = value;
        }

        setUnsavedChanges(true);
    };

    const setError = (outerIndex, innerIndex, value) => {
        /*  let answersCopy = [...answersWithError];
        answersCopy = answersCopy.filter((x) => x !== `${outerIndex}-${innerIndex}`);
        if (value) {
          answersCopy.push(`${outerIndex}-${innerIndex}`);
        }
        setAnswersWithError(answersCopy); */
    };

    useEffect(() => {
        const initBanks = async () => {
            const {data, error} = await getBanks(null, null, null, null);
            if (error) {
                handleError(error);
                return;
            }

            const response = data.data.map((item) => {
                return {
                    value: item.name,
                    label: item.name,
                };
            });
            setBanks(response);
        };

        initBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addQuestionToArray = (questionTypeArray: Question): void => {
        questionTypeArray.response = questionTypeArray.response || [];
        const optCopy = questionTypeArray.options.map((o) => ({
            ...o,
            response: o.responseType === EnumQuestionOptionResponseType.Id ? uuidv4() : undefined,
        }));
        questionTypeArray.response.push(optCopy);
        setMySurvey([...mySurvey]);
        setUnsavedChanges(true);
    };

    const removeQuestionFromArray = (questionArray: Question, questionIndex: number): void => {
        questionArray.response.splice(questionIndex, 1);
        setMySurvey([...mySurvey]);
        setUnsavedChanges(true);
    };

    const renderNavItem = (key: string, label: string, disabled: boolean = false, depth: number = 0): JSX.Element => {
        depth = 30 * depth + 10;
        return (
            <Nav.Link style={{paddingLeft: depth}} disabled={disabled} eventKey={disabled ? undefined : key}>
                {label}
            </Nav.Link>
        );
    };

    const renderNavArrayQuestion = (question: Question, parentKey: string, index: number): JSX.Element => {
        var blocchi = arrayQuestionLength(question);
        if (blocchi > 0) {
            var arrayQuestions = question.response;
            return (
                <>
                    {arrayQuestions.map((resp: Question[], i: number) => {
                        var recapFields = resp.filter((r) => !!r.isBlockName);
                        var recapValues = recapFields.map((r) => r.response);
                        var recap = "#" + (i + 1) + " " + recapValues.join(", ");

                        var questionId = resp.find((q) => q.question === "BlockId" && q.responseType === EnumQuestionOptionResponseType.Id);

                        var linkedServices = question.services.items.filter((s) => s.questionId === questionId?.response);
                        const accordionKey = `${parentKey}_${i}`;
                        return (
                            <Nav.Item key={`${_.uniqueId()}`}>
                                {renderNavItem(accordionKey, recap, false, 0)}
                                {linkedServices.length > 0 && (
                                    <Nav.Item key={_.uniqueId()}>
                                        {!accourdionStatus[accordionKey] && (
                                            <Button
                                                variant="link"
                                                className="w-100 text-start"
                                                onClick={() =>
                                                    setAccordionStatus({
                                                        ...accourdionStatus,
                                                        [accordionKey]: !accourdionStatus[accordionKey],
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon width={20} height={20} icon={faChevronCircleUp}
                                                                 className="mr-2"/>
                                                Servizi proposti
                                            </Button>
                                        )}
                                        {accourdionStatus[accordionKey] && (
                                            <Button
                                                variant="link"
                                                className="w-100 text-start"
                                                onClick={() =>
                                                    setAccordionStatus({
                                                        ...accourdionStatus,
                                                        [accordionKey]: !accourdionStatus[accordionKey],
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon width={20} height={20} icon={faChevronCircleDown}
                                                                 className="mr-2"/>
                                                Servizi proposti
                                            </Button>
                                        )}
                                        {!accourdionStatus[accordionKey] &&
                                            linkedServices.map((s, j) => {
                                                var serviceLabel = availableServices.find((x) => x._id === s.serviceId)?.displayName || "Sconosciuto";
                                                return renderNavItemService(serviceLabel, s, 1, question, questionId.response);
                                            })}
                                    </Nav.Item>
                                )}
                            </Nav.Item>
                        );
                    })}
                </>
            );
        }
        return null;
    };

    const arrayQuestionLength = (question: Question): number => {
        return !!question && !!question.response ? question.response.length : 0;
    };

    const renderNavItemAddQuestionButton = (questionTypeArray: Question, depth: number = 0): JSX.Element => {
        depth = 30 * depth + 10;
        return (
            <Nav.Item key={_.uniqueId()}>
                <Nav.Link style={{paddingLeft: depth}} onClick={() => addQuestionToArray(questionTypeArray)}>
                    <FontAwesomeIcon icon={faPlus}/> Aggiungi
                </Nav.Link>
            </Nav.Item>
        );
    };

    const renderNavItemService = (label: string, service: ServiceCustomer, depth: number = 0, parent: Question, questionId: string): JSX.Element => {
        depth = 30 * depth + 10;

        return (
            <Nav.Item key={_.uniqueId()} className="itemLink" style={{padding: `.5rem 0px .5rem ${depth}px`}}>
                <button
                    title="Elimina servizio"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        removeServiceToCustomer(service._id, parent, questionId);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            removeServiceToCustomer(service._id, parent, questionId);
                        }
                    }}
                >
                    <FontAwesomeIcon className="text-danger" icon={faTimes}/>
                </button>
                <Nav.Link style={{display: "inline", padding: "0px"}}
                          href={`/customers/${customerId}/services/${service._id}`} target="_blank">
                    <span>{label}</span>
                    <FontAwesomeIcon icon={faExternalLinkAlt}/>
                </Nav.Link>
            </Nav.Item>
        );
    };

    const renderNavSection = (section: Section, parentKey: string, sectionIndex: number): JSX.Element => {
        var arrayQuestions = section.questions.filter((q) => q.responseType === EnumQuestionOptionResponseType.Array);
        var otherQuestions = section.questions.filter((q) => q.responseType !== EnumQuestionOptionResponseType.Array);
        const accordionKey = parentKey + "_" + sectionIndex;
        if (arrayQuestions.length > 0) {
            return (
                <>
                    <Nav.Item key={_.uniqueId()} className="ps-4">
                        {/* {renderNavItem(accordionKey, section.title, true, 1)} */}
                        {!accourdionStatus[accordionKey] && (
                            <Button
                                variant="link"
                                className="w-100 text-start"
                                onClick={() =>
                                    setAccordionStatus({
                                        ...accourdionStatus,
                                        [accordionKey]: !accourdionStatus[accordionKey],
                                    })
                                }
                            >
                                <FontAwesomeIcon width={20} height={20} icon={faChevronCircleUp} className="mr-2"/>
                                {section.title}
                            </Button>
                        )}
                        {accourdionStatus[accordionKey] && (
                            <Button
                                variant="link"
                                className="w-100 text-start"
                                onClick={() =>
                                    setAccordionStatus({
                                        ...accourdionStatus,
                                        [accordionKey]: !accourdionStatus[accordionKey],
                                    })
                                }
                            >
                                <FontAwesomeIcon width={20} height={20} icon={faChevronCircleDown} className="mr-2"/>
                                {section.title}
                            </Button>
                        )}
                        {!accourdionStatus[accordionKey] && (
                            <div className="ps-4">
                                {arrayQuestions.map((q, i) => {
                                    return (
                                        <React.Fragment key={`${q.order}_${i}`}>
                                            {renderNavItemAddQuestionButton(q, 0)}
                                            {renderNavArrayQuestion(q, accordionKey, i)}
                                        </React.Fragment>
                                    );
                                })}
                                {otherQuestions.length > 0 && (
                                    <Nav.Item
                                        key={`${accordionKey}__${section.title}`}>{renderNavItem(accordionKey, "Info " + section.title, false, 0)}</Nav.Item>
                                )}
                            </div>
                        )}
                    </Nav.Item>
                </>
            );
        } else {
            return <Nav.Item
                key={`${accordionKey}__${section.title}`}>{renderNavItem(accordionKey, section.title, false, 1)}</Nav.Item>;
        }
    };

    const renderNavTabWithMoreSections = (tabSection: TabSections): JSX.Element => {
        return (
            <Nav.Item key={`${tabSection.key}_${tabSection.order}-${tabSection.name}`}>
                {renderNavItem(undefined, tabSection.name, true)}
                {tabSection.sections.map((section, sectionIndex) => {
                    return (
                        <React.Fragment
                            key={`${tabSection.key}_${sectionIndex}`}>{renderNavSection(section, tabSection.key, sectionIndex)} </React.Fragment>
                    );
                })}
            </Nav.Item>
        );
    };

    const renderTabNavigation = (): JSX.Element => {
        return (
            <>
                {tabsSections.map((tabSection, tabSectionIndex) => {
                    if (tabSection.sections.length === 1) {
                        return <Nav.Item
                            key={`${tabSection.key}_${tabSectionIndex}`}>{renderNavItem(tabSection.key, tabSection.name)}</Nav.Item>;
                    } else {
                        return renderNavTabWithMoreSections(tabSection);
                    }
                })}
            </>
        );
    };

    const renderPanelQuestion = (section: Section, question: Question, outerIndex: number, index: number, callBack?: () => void): JSX.Element => {
        return (
            <div className={`my-3 ${overrideClass ? overrideClass.input.class : ""}`}
                 key={`block_${section.order}_${index}`}>
                <QuestionComponent
                    key={"q_" + question.order}
                    setError={(err) => {
                        setError(outerIndex, index, err);
                    }}
                    readOnly={readOnly}
                    inputName={"q_" + question.order}
                    question={question}
                    updateAnswer={(val) => {
                        setResponse(section, question, outerIndex, index, val);
                        const specialFeeQuestion = ["Importo rata", "Numero rate"];
                        if (specialFeeQuestion.includes(question.question) && (section.title === "CRIF" || section.title === "Altri SIC")) {
                            calculateFirstFee(section, outerIndex);
                        }

                        if (question.question === "Id Contratto") {
                            setMySurvey([...mySurvey]);
                            setUnsavedChanges(true);
                        }

                        if (callBack) callBack();
                    }}
                    bankList={banks}
                    onInputLostFocus={() => {
                        const debtSpecialQuestion = ["Bonis", "Importo insoluto", "In corso", "Importo rata"];
                        const isSpecial = debtSpecialQuestion.includes(question.question);
                        if (isSpecial) {
                            updateDebtStatus(section, isSpecial, question.question);
                        }
                        const finalConsiderationUpdateQuestion = ["Opportunità di recupero", "Cancellazioni da effettuare", "Debito in bonis", "Debito in malis"]
                        if (finalConsiderationUpdateQuestion.includes(question.question)) {
                            updateFinalConsiderationScreening(question.question);
                        }
                    }}
                />
            </div>
        );
    };

    const renderPanelSectionContent = (section: Section, outerIndex: number): JSX.Element => {
        return (
            <div key={`${section.order}_${outerIndex}`} className="row">
                <div className={`${overrideClass ? overrideClass.survey.class : "col-11 offset-1 text-start my-3"}`}>
                    <h3 className="text-center mt-1">{section.title}</h3>
                    <div className={overrideClass ? overrideClass.row.class : ""}>
                        {section.questions
                            .filter((q) => q.responseType !== EnumQuestionOptionResponseType.Array)
                            .map((question, innerIndex) => renderPanelQuestion(section, question, outerIndex, innerIndex))}
                    </div>
                </div>
                {outerIndex < mySurvey.length - 1 && <hr className="my-3"/>}
            </div>
        );
    };

    //#region BLOCCO DEGLI AUTOMATISMI

    // CALCOLO CANCELLAZIONI (CRIF, ALTRI SIC)

    const updateFinalConsiderationScreening = useCallback((questionTitle: string): void => {

        const italyBankQuestionResponse = parseFloat(
            mySurvey
                .find((x) => x.title === "Banca D'Italia")?.questions
                .find((q) => q.question === questionTitle)?.response || 0
        );

        const crifQuestionResponse = parseFloat(
            mySurvey
                .find((x) => x.title === "CRIF")?.questions
                .find((q) => q.question === questionTitle)?.response || 0
        )

        const otherSicQuestionResponse = parseFloat(
            mySurvey
                .find((x) => x.title === "Altri SIC")?.questions
                .find((q) => q.question === questionTitle)?.response || 0
        )

        const finalConsiderationBlock = mySurvey
            .find((x) => x.title === "Considerazioni Finali")
            .questions.find((q) => q.question === questionTitle);

        if (!finalConsiderationBlock) return;

        finalConsiderationBlock.response = italyBankQuestionResponse + crifQuestionResponse + otherSicQuestionResponse;
        setMySurvey([...mySurvey]);
        setUnsavedChanges(true);

    }, [mySurvey, setUnsavedChanges])

    const cancellationsToMadeAutomatism = useCallback(
        (section: Section, serviceName: string) => {
            const validCombinations = [
                {section: "Banca D'Italia", service: "CancellazioneSegnalazioniSic"},
                {section: "Banca D'Italia", service: "CancellazioneSegnalazioniProtesti"},
                {section: "CRIF", service: "CancellazioneSegnalazioniSic"},
                {section: "CRIF", service: "CancellazioneSegnalazioniProtesti"},
                {section: "Altri SIC", service: "CancellazioneSegnalazioniSic"},
                {section: "Altri SIC", service: "CancellazioneSegnalazioniProtesti"},
            ];

            const isValidCombination = validCombinations.some(
                (combination) => combination.section === section.title && combination.service === serviceName
            );

            if (isValidCombination) {
                const questionOptionTitle = "Cancellazioni da effettuare"

                const cancellationsToMade = section.questions.find((q) => q.question === questionOptionTitle);

                if (!cancellationsToMade) return;

                const oldCancellationToMade: number = cancellationsToMade.response ? cancellationsToMade.response : 0;
                cancellationsToMade.response = oldCancellationToMade + 1;

                setMySurvey([...mySurvey]);
                setUnsavedChanges(true);

                updateFinalConsiderationScreening(questionOptionTitle)
            }
            return;
        },
        [mySurvey, setUnsavedChanges]
    );

    // CALCOLO OPPORTUNITA DI RECUPERO (CRIF, ALTRI SIC)
    const opportunityRecoveryAutomatism = useCallback(
        (section: Section, serviceName: string) => {
            const validCombinations = [
                {section: "Banca D'Italia", service: "RecuperoPrestito"},
                {section: "CRIF", service: "RecuperoPrestito"},
                {section: "Altri SIC", service: "RecuperoPrestito"},
            ];

            const isValidCombination = validCombinations.some(
                (combination) => combination.section === section.title && combination.service === serviceName
            );

            if (isValidCombination) {

                const questionOptionTitle = "Opportunità di recupero"

                const opportunityRecoveryQuestion = section.questions.find((q) => q.question === "Opportunità di recupero");

                if (!opportunityRecoveryQuestion) return;

                const oldOpportunityRecovery: number = opportunityRecoveryQuestion.response ? opportunityRecoveryQuestion.response : 0;
                opportunityRecoveryQuestion.response = oldOpportunityRecovery + 1;

                setMySurvey([...mySurvey]);
                setUnsavedChanges(true);

                updateFinalConsiderationScreening(questionOptionTitle)
            }
            return;
        },
        [mySurvey, setUnsavedChanges]
    );

    // Calcolo debito in BONIS, MALUS e BONIS MENSILE
    const updateDebtStatus = useCallback(
        (section: Section, isSpecial: boolean, questionTitle: string) => {
            if (isSpecial) {
                const sectionBlock = mySurvey.find((x) => x.title === section.title);

                if (!sectionBlock) return;

                const result = sectionBlock.questions.find(
                    (x) =>
                        x.question === "Risultanze Centrale Rischi Banca D'Italia" ||
                        x.question === "Risultanze presenti in CRIF" ||
                        x.question === "Risultanze presenti in altri SIC"
                );

                if (!result) return;

                const debtBonisMalisResponse: AutomatismValue = result.response.reduce(
                    (acc: AutomatismValue, block: Question[]) => {
                        let currentType = null;

                        const onGoingQuestion = block.find((x) => x.question === "In corso");
                        const bonisBlock = block.find((x) => x.question === "Bonis");

                        for (let i = 0; i < block.length; i++) {
                            const value = block[i];

                            if (bonisBlock) {
                                currentType = bonisBlock.response === "Si" ? "bonis" : "malis";
                            }

                            if (section.title === "CRIF" || section.title === "Altri SIC") {
                                if (onGoingQuestion.question === value.question && onGoingQuestion.response === "Si") {
                                    if (currentType === "bonis") {
                                        const trancheBlock = block.find((x) => x.question === "Importo rata");
                                        const importoRata = parseFloat(trancheBlock.response || 0);
                                        acc["monthBonis"] += importoRata;
                                    }
                                }
                            }

                            if (value.question === "Importo insoluto" && currentType) {
                                const importoInsoluto = parseFloat(value.response || 0);
                                acc[currentType] += importoInsoluto;
                            }
                        }

                        return acc;
                    },
                    {bonis: 0, malis: 0, monthBonis: 0}
                );

                const questionToUpdateBonis = sectionBlock.questions.find((q) => q.question === "Debito in bonis");
                const questionToUpdateMonthBonis = sectionBlock.questions.find((q) => q.question === "Debito mensile in bonis");
                const questionToUpdateMalis = sectionBlock.questions.find((q) => q.question === "Debito in malis");

                if (questionToUpdateBonis) {
                    questionToUpdateBonis.response = debtBonisMalisResponse.bonis;
                }
                if (questionToUpdateMonthBonis) {
                    questionToUpdateMonthBonis.response = debtBonisMalisResponse.monthBonis;
                }
                if (questionToUpdateMalis) {
                    questionToUpdateMalis.response = debtBonisMalisResponse.malis;
                }

                setMySurvey([...mySurvey]);
                setUnsavedChanges(true);

                updateFinalConsiderationScreening("Debito in bonis")
                updateFinalConsiderationScreening("Debito in malis")

            }
        },
        [mySurvey, setUnsavedChanges]
    );

    // Calcolo importo rata iniziale
    const calculateFirstFee = useCallback(
        (section: Section, index: number) => {
            const sectionBlock = mySurvey.find((x) => x.title === section.title);

            if (!sectionBlock) return;

            const result = sectionBlock.questions.find(
                (q) => q.question === "Risultanze presenti in CRIF" || q.question === "Risultanze presenti in altri SIC"
            );

            if (!result) return;

            const currentQuestionBlock = result.response[index];

            const importFee = parseFloat(currentQuestionBlock.find((q) => q.question === "Importo rata").response || 0);
            const trancheNumber = parseFloat(currentQuestionBlock.find((q) => q.question === "Numero rate").response || 0);

            const firstImport = currentQuestionBlock.find((q) => q.question === "Importo iniziale") as Question;

            if (!firstImport) return;

            firstImport.response = importFee * trancheNumber;
            setMySurvey([...mySurvey]);
            setUnsavedChanges(true);
        },
        [mySurvey, setUnsavedChanges]
    );

    // #endregion

    const addNewServiceToCustomer = useCallback(
        async (service, section: Section, parent: Question, questionId: string, subTitle = "",otherInfo:{bank: string,nContract:string}) => {
            if (!parent.services.enabled) {
                return;
            }

            const {data, error} = await newServiceCustomer({
                ...service,
                serviceCustomerRefId: id,
                subTitle: subTitle,
                bank: otherInfo.bank || "",
                nContract: otherInfo.nContract || ""
            });
            if (error) {
                handleError(error);
                return;
            }
            parent.services.items = parent.services.items || [];

            const serviceName = availableServices.find((x) => x._id === service.serviceId).internalName;

            data.questionId = questionId;
            parent.services.items.push(data);
            setMySurvey([...mySurvey]);
            setUnsavedChanges(true);
            showSuccessToast("Servizio aggiunto correttamente");
            doUpdateSurvey();
            if (serviceName === "RecuperoPrestito") {
                opportunityRecoveryAutomatism(section, serviceName);
            }
            if (serviceName === "CancellazioneSegnalazioniSic" || serviceName === "CancellazioneSegnalazioniProtesti") {
                cancellationsToMadeAutomatism(section, serviceName);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newServiceCustomer, id, mySurvey, availableServices, showSuccessToast, handleError]
    );

    const removeServiceToCustomer = useCallback(
        async (serviceid: string, parent: Question, questionId: string) => {
            const removeServiceCallBack = async () => {
                if (!parent.services.enabled) {
                    return;
                }

                const {error} = await deleteServiceCustomer(serviceid);
                if (error) {
                    handleError(error);
                    return;
                }

                const updatedServices = parent.services.items.filter((service) => service._id !== serviceid || service.questionId !== questionId);

                parent.services.items = updatedServices;

                setMySurvey([...mySurvey]);
                setUnsavedChanges(true);
                showSuccessToast("Servizio rimosso correttamente");
                doUpdateSurvey();
            };
            openEthosConfirmModal("Sicuro di voler eliminare il servizio?", removeServiceCallBack, null, "Conferma eliminazione");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newServiceCustomer, id, mySurvey, showSuccessToast, handleError]
    );

    const renderPanelQuestionArrayContent = (
        section: Section,
        parent: Question,
        parentIndex: number,
        questions: Question[],
        outerIndex: number
    ): JSX.Element => {
        var recapFields = questions.filter((r) => !!r.isBlockName);
        var recapValues = recapFields.map((r) => r.response);
        var title = "# " + recapValues.join(", ");

        var serviceSubTitle = questions
            .filter((r) => !!r.isServiceName)
            .map((r) => r.response)
            .join(", ");

        var info = questions
            .filter((r) => r.question === "Creditore" || r.question === "Id Contratto")
            .reduce((acc, response) => {
                return {
                    bank: response.question === "Creditore" ? response.response : acc.bank,
                    nContract: response.question === "Id Contratto" ? response.response : acc.nContract,
                };
            }, { bank: "", nContract: "" });

        const updateTitle = () => {
            var temp = questions.filter((r) => !!r.isBlockName);
            temp = temp.map((r) => r.response);
            title = "# " + temp.join(", ");
        };

        const questionId = questions.find((q) => q.question === "BlockId" && q.responseType === EnumQuestionOptionResponseType.Id);

        return (
            <div key={`${section.order}__${outerIndex}-${parentIndex}`} className="row">
                <div className={`${overrideClass ? overrideClass.survey.class : "col-11 offset-1 text-start my-3"}`}>
                    <div className="row mt-1">
                        <h3 className="col-9 text-start">{title}</h3>
                        {!readOnly && (
                            <div className="col-3 text-end">
                                <Button className="align-self-start" variant="danger"
                                        onClick={() => removeQuestionFromArray(parent, outerIndex)}>
                                    <FontAwesomeIcon className="text-white" icon={faTimes}/> Rimuovi
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={overrideClass ? overrideClass.row.class : ""}>
                        {questions
                            .filter((q) => q.responseType !== EnumQuestionOptionResponseType.Array)
                            .map((question, innerIndex) => renderPanelQuestion(section, question, outerIndex, innerIndex, updateTitle))}
                    </div>
                </div>
                {!!customerId && parent.services && availableServices && (
                    <div className="row mb-3">
                        <div className="col-10 offset-1">
                            <div className="w-100">
                                <div className="my-3">
                                    <h4 style={{textAlign: "center"}}>Creazione Serivizi in Bozza</h4>
                                    <AddServiceCustomerForm
                                        customerId={customerId}
                                        services={availableServices}
                                        addServiceCustomer={(service) => {
                                            addNewServiceToCustomer(service, section, parent, questionId.response, serviceSubTitle,info);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderTabPanels = (tabSection: TabSections): JSX.Element => {
        // section panels
        var panels = tabSection.sections.map((section, sectionIndex) => (
            <Tab.Pane key={tabSection.key + "_" + sectionIndex} eventKey={tabSection.key + "_" + sectionIndex}>
                {renderPanelSectionContent(section, sectionIndex)}
            </Tab.Pane>
        ));

        // missing questionArray panels
        tabSection.sections.forEach((s, sectionIndex) =>
            s.questions
                .filter((q) => q.responseType === EnumQuestionOptionResponseType.Array && !!q.response)
                .forEach((questionArray, questionArrayIndex) =>
                    questionArray.response.forEach((question, questionIndex) => {
                        panels.push(
                            <Tab.Pane
                                key={tabSection.key + "_" + sectionIndex + "_" + questionIndex}
                                eventKey={tabSection.key + "_" + sectionIndex + "_" + questionIndex}
                            >
                                {renderPanelQuestionArrayContent(s, questionArray, questionArrayIndex, question, questionIndex)}
                            </Tab.Pane>
                        );
                    })
                )
        );

        return <>{panels}</>;
    };

    return (
        <div className="row mb-3">
            <div className="col-12">
                {!readOnly ? (
                    <div className="row text-start mb-3">
                        <div className="col-12">
                            <button
                                onClick={() => doUpdateSurvey()}
                                className={classNames("btn btn-primary", {
                                    disabled: answersWithError && answersWithError.length,
                                })}
                            >
                                <FontAwesomeIcon className="mr-2" icon={faSave}/>
                                Salva Questionario
                            </button>
                        </div>
                    </div>
                ) : null}

                {tabsSections.length > 0 && (
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={5}>
                                <Nav variant="pills" className="flex-column">
                                    {renderTabNavigation()}
                                </Nav>
                            </Col>
                            <Col sm={7}>
                                <Tab.Content>
                                    {tabsSections.map((tabSection, idx) => {
                                        if (tabSection.sections.length === 1) {
                                            return (
                                                <Tab.Pane key={`${tabSection.key}_${idx}`} eventKey={tabSection.key}>
                                                    {tabSection.sections.map((section, i) => renderPanelSectionContent(section, i))}
                                                </Tab.Pane>
                                            );
                                        } else {
                                            return <React.Fragment
                                                key={`${tabSection.key}_${idx}`}> {renderTabPanels(tabSection)}</React.Fragment>;
                                        }
                                    })}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                )}

                {otherSections.map((surveyItem, outerIndex) => {
                    return renderPanelSectionContent(surveyItem, outerIndex);
                })}
            </div>
        </div>
    );
};

export default SurveyStepElement;
